export const months = ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'];
export const monthsUppercase = ['GEN', 'FEB', 'MAR', 'APR', 'MAG', 'GIU', 'LUG', 'AGO', 'SET', 'OTT', 'NOV', 'DIC'];
export const monthsFull = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];

export const daysOfWeek = ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'];
export const daysOfWeekFull = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'];

function isValidDate (date) {
  if (!date) {
    return false;
  }

  return date instanceof Date;
}

export function toDateTime (dateTime) {
  if (!dateTime) {
    return null;
  }

  return new Date(dateTime);
}

export function getFullDay (dateTime) {
  if (!isValidDate(dateTime)) {
    return '';
  }

  return String(dateTime.getDate()).padStart(2, '0');
}

export function getDay (dateTime) {
  if (!isValidDate(dateTime)) {
    return '';
  }

  return dateTime.getDate();
}

export function getDayOfWeekName (dayOfWeekNumber, myDaysOfWeek = daysOfWeek) {
  if (!Number.isInteger(dayOfWeekNumber)) {
    return '';
  }

  return myDaysOfWeek[dayOfWeekNumber];
}

export function getYear (dateTime, number = 4) {
  if (!isValidDate(dateTime)) {
    return '';
  }

  return dateTime.getFullYear();
}

export function getMonthNumber (dateTime) {
  if (!isValidDate(dateTime)) {
    return null;
  }

  return dateTime.getMonth();
}

export function getMonthMm (dateTime) {
  if (!isValidDate(dateTime)) {
    return null;
  }

  return String(dateTime.getMonth() + 1).padStart(2, '0');
}

export function getMonthName (monthNumber, myMonths = months) {
  if (!Number.isInteger(monthNumber)) {
    return '';
  }

  return myMonths[monthNumber];
}

export function getYmd (dateTime) {
  if (!isValidDate(dateTime)) {
    return null;
  }

  return getYear(dateTime) + '-' + getMonthMm(dateTime) + '-' + getFullDay(dateTime);
}

export function getDateFormatted (dateTime, months) {
  if (!isValidDate(dateTime)) {
    return null;
  }

  return getDay(dateTime) + ' ' + getMonthName(getMonthNumber(dateTime), months) + ' ' + getYear(dateTime);
}

export function addDays (date, days) {
  const result = new Date(date);

  result.setDate(result.getDate() + days);

  return result;
}

export function subDays (date, days) {
  const result = new Date(date);

  result.setDate(result.getDate() - days);

  return result;
}
